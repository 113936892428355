<template>
  <button
    class="w-full border whitespace-nowrap flex justify-center items-center gap-4"
    :class="{
      'py-4 px-2 font-medium rounded-2xl': size === 'lg',
      'py-3.5 px-2 text-base rounded-lg': size === 'base',
      // 'py-2 px-4 text-sm rounded-lg': size === 'sub',
      'py-1.5 px-2 text-sm rounded-lg': size === 'sm',
      'py-1 px-2 text-2sm rounded-md': size === 'xs',
      'py-1 px-3 text-2sm rounded-full': size === '2xs',

      'bg-prime-default border-prime-default text-white':
        color === 'prime' && !disabled,
      'bg-black border-black text-white': color === 'black' && !disabled,
      ' bg-[#F0F1F6] border-zipup-gray-4 text-prime-default font-medium':
        color === 'second',
      'border-zipup-gray-3 text-black bg-white': color === 'default',
      'border-zipup-gray-3 text-zipup-gray-2 bg-white font-normal':
        color === 'third',
      'bg-prime-50': active,

      'bg-prime-default bg-opacity-30 border-prime-100 text-white':
        color === 'prime' && disabled,
      'bg-zipup-gray-3 bg-opacity-50': disabled && color !== 'prime',
      'bg-sub text-black border-sub': color === 'sub',
    }"
    :style="{
      backgroundColor: customColor && !disabled ? customColor : '',
      borderColor: customColor && !disabled ? customColor : '',
    }"
    :disabled="disabled"
  >
    <slot name="leading" />
    <span class="relative -bottom-[1px]">
      {{ title }}
    </span>
  </button>
</template>
<script lang="ts">
export default defineComponent({
  props: {
    title: {
      type: String,
      default: () => 'Labels',
    },
    color: {
      type: String,
      default: 'prime', // prime, default, second, third, sub
    },
    customColor: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg', // xs, sm, base, lg
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    logContents: {
      type: Object as PropType<any>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      log: `${this.$route.path}@${this.title}`,
    }
  },
})
</script>
